import axios from "axios";

const api = axios.create({
  baseURL: "https://yobobackend.sqaby.org/api",
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("authToken");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       console.log("Unauthorized, logging out...");
//       localStorage.removeItem("authToken");
//       window.location.href = "/login";
//     }
//     return Promise.reject(error);
//   }
// );

const http = {
  get: async (url, params = {}) => {
    try {
      const res = await api.get(url, { params });
      return res.data;
    } catch (error) {
      console.error("GET request error: ", error);
      throw error.response;
    }
  },

  post: async (url, data) => {
    try {
      const res = await api.post(url, data);
      return res.data;
    } catch (error) {
      console.error("POST request error: ", error);
      throw error.response;
    }
  },

  put: async (url, data) => {
    try {
      const res = await api.put(url, data);
      return res.data;
    } catch (error) {
      console.error("PUT request error: ", error);
      throw error.response;
    }
  },

  delete: async (url) => {
    try {
      const res = await api.delete(url);
      return res.data;
    } catch (error) {
      console.error("DELETE request error: ", error);
      throw error.response;
    }
  },
};

export default http;
