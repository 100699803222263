import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/theme.scss";
import AppRoutes from "./routes";

createRoot(document.getElementById("root")).render(
  <BrowserRouter>
    
    <AppRoutes />
  </BrowserRouter>
);
