import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "../components/Authentication/Login";
import Dashboard from "../components/Dashboard";

const Index = () => {
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) setUserIsLoggedIn(true);
  }, []);

  useEffect(() => {
    document.title = "Yobo Restro Admin Panel";
    const token = localStorage.getItem("authToken");
    if (token) setUserIsLoggedIn(true);
  }, []);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      {!userIsLoggedIn && (
        <Route path="*" element={<Navigate to={"/login"} />} />
      )}
      {userIsLoggedIn && (
        <Route path="*" element={<Navigate to={"/dashboard"} />} />
      )}
      <Route path="/dashboard" element={<Dashboard />} />
    </Routes>
  );
};

export default Index;
